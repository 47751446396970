.Dashboard {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.Dashboard .header {
  height: 13em;
  display: flex;
  flex-direction: column;
  font-size: 0.66em;
  z-index: 3;
}


.bars {
  height: auto;
  width: 100%;
}

.bar.mana {
  background: #CEE9EF;
  height: 6em;
  width: 100%;
  border-bottom: 5px dashed #00090b;
}

.mana .amount {
  top: 1em;
  left: 1.75em;
  line-height: 110%;
  font-size: 175%;
}

.mana .amount span {
  font-size: 0.66em;
}

.bar {
  position: relative;
}

div.title {
  position: absolute;
  top: 1.75em;
  left: -1.5em;
  font-size: 1.3em;
  transform: rotate(90deg);
  font-weight: bolder;
  font-family: "Press Start 2P";
}

.xp .title {
  left: -0.5em;
  top: 0.9em;
}

.bar.xp,
.bar.feed {
  background: #ceefd4;
  height: 4em;
  width: 100%;
  border-bottom: 5px dashed #00090b;
}

.bar.feed {
  background: #ffd722;
  border-bottom: 5px solid #ffd722;
  /* display: none; */
}

.feed .title {
  font-size: 0.9em;
  left: -1.25em;
  top: 1.75em;
}

.bar.feed .the-feed {
  position: relative;
  top: 0;
  right: 0;
  width: calc(100%);
  height: 100%;
  /* margin-top: 2.5px; */
  /* background: #00090b;
    opacity: 0; */
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  overflow: none;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  padding-left: 2em;
}

.the-feed li {
  opacity: 0;
  /* animatino forwards */
  animation-fill-mode: forwards;;
}

.the-feed::after,
.tip-content::after,
.tip-content::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 2em);
  height: 100%;
  /* from see through to opaque */
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 66%, rgba(255, 215, 34, 0.5) 70%, rgba(255, 215, 34, 1) 90%);
  /* opacity: 0.5; */
  z-index: 4;
  margin-top: 2.5px;
}

.the-feed {
  list-style: none;
  padding-top: 0.5em;
}

.feed .filter {
  position: absolute;
  right: 1em;
  top: 0.5em;
  z-index: 3;

}

.feed .filter * {
  color: #00090b !important;
  /* padding-top: 0.5em; */
  font-size: 3em;
  font-weight: bolder;
}

.tips .bell {
  /* position on left side as opposed to right */
  position: absolute;
  left: 2em;
  top: 0.5em;
  z-index: 3;
}

.tips .bell * {
  color: #00090b !important;
  /* padding-top: 0.5em; */
  font-size: 3em;
  font-weight: bolder;
}

.the-feed .address {
  /* no text overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* no text selection */
  -webkit-touch-callout: none;
  user-select: none;
  margin-right: 1em;
}

.bar.tips {
  background: #07f55e;
  border-bottom: dashed #00090b 0.25em;
  display: flex !important;
  flex-direction: row;
}

.bar.tips * {
  /* color: white; */
}

.bar.tips .tip-content::after {
  content: "";
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 85%, rgba(7, 245, 94, 0.5) 90%, rgba(7, 245, 94, 1) 100%);
  height: calc(100% - 0.25em);
}

.bar.tips .tip-content::before {
  content: "";
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 85%, rgba(7,245,94, 0.5) 99%, rgba(7,245,94, 1) 100%);
  height: calc(100% - 0.25em);
  left: 0;
  /* display: none; */
  z-index: 2;
}
.tip-content > div {
  opacity: 1;
  transition: opacity 0.8s ease-in-out;
}
.tip-content > div.entering, .tip-content > div.entered, .tip-content > div.exiting {
  /* fadeOut */
  opacity: 0;
}

.increment,
.decrement {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  font-size: 2em;
  min-width: 2em;
}

.increment *,
.decrement * {
  transition: all 0.2s ease-in-out;
}

.increment {
  /* rotate clockwise 90deg */
  transform: rotate(90deg);
}

.decrement {
  /* rotate counterclockwise 90deg */
  transform: rotate(-90deg);
}

.increment:hover *,
.decrement:hover *,
.increment:active *,
.decrement:active * {
  cursor: pointer;
  color: #fff !important;
}

.tip-content {
  padding-left: 3em;
  height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: calc(98%);
  overflow-x: auto;
  /* no wrap */
  flex: 4;
  white-space: nowrap;
  margin-left: 1.25em;
  padding-right: 1.25em;
}

.tip-content>div {
  max-width: 100%;
}

.tip-content>div * {
  text-align: left;

}

/* hide scrollbar if possible */
.tip-content::-webkit-scrollbar {
  display: none;
}

/* or maybe this */
.tip-content::-webkit-scrollbar-thumb {
  display: none;
}




.amount {
  position: absolute;
  top: 1.2em;
  left: 2.5em;
  font-weight: bolder;
  font-size: 1.25em;
}

.lvl-amount {
  position: absolute;
  top: 1.25em;
  right: 1em;
  font-weight: bolder;
  font-size: 1em;
  text-align: right;
}

.amount>span {
  font-size: 0.8em;
}

.mana-background,
.xp-background {
  background: #14c6ee;
  width: 5%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.xp-background {
  background: #07f55e;
}

.account {
  color: rgb(255, 255, 255);
  text-align: right;
  margin: 0.5em;
  font-size: 1.25em;
}

.cards>div {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.cards>div:nth-of-type(1) {
  margin-left: auto;
  height: max-content;
  /* height: 100%; */

}

/* small width < 420 height should be 100% */
@media screen and (max-width: 420px) {
  .cards>div:nth-of-type(1) {
    height: 100%;
  }
}

.cards>div:nth-of-type(2) {
  margin-right: auto;
  height: max-content;
  /* height: 100%; */
  margin-top: 7.5em;
}

.cards {
  display: flex;
  flex-direction: row;
  /* padding-top: 2em; */
  max-height: calc(100% - 6em);
  overflow: auto;
  flex: 1;
}

.card {
  width: 15em;
  max-width: calc(50vw - 2em);
  min-height: 15em;
  margin: 0.5em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.25em;
}

.card-title,
.card-subtitle {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-title {
  font-size: 0.85em !important;
}

.card-subtitle {
  font-size: 0.75em !important;
}

.card>div:nth-of-type(2) {
  font-size: 0.85em;
}

.card>button {
  width: min-content;
  margin: 1ch auto;
  border-radius: 0.4em;
  transition: background 0.2s ease-in-out, border-radius 0.2s ease-in-out;
}

/* when button is actively selected/clicked animate background color transition */
.card>button:hover {
  background: #eee;
  cursor: pointer;
  border-radius: 0.6em;
}

.card>button:active {
  background: #ddd;
  transition: background 0.2s ease-in-out;
  border-radius: 0.75em;

}

.card>button.invert {
  background: #00090b;
}

#pullups {
  height: 100vh;
  width: 100vw;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

#pullups * {
  pointer-events: all;
}


.pull-up {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5em;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0 1.5em;
  width: 100%;
  max-width: 30em;
  bottom: 0em;
  height: calc(100% - 11em);
  overflow: auto;
  overflow-x: hidden;
  background-color: #00090b;
  transition: all 0.6s ease-in-out, background 0s linear;
  border-radius: 0.5em 0.5em 0 0;
  opacity: 1 !important;
  margin: auto;
}

@media screen and (min-width: 92em) {
  .pull-up {
    margin: unset;
    margin-right: auto;
    margin-left: 0.5em;
  }

  .pull-up-chat {
    margin-right: unset;
    margin-left: auto;
    margin-right: 0.5em;
  }
}

.pull-up-chat-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  overflow: auto;
  height: 100%;
  border-radius: 0.5em 0.5em 0 0;
  opacity: 1 !important;
  margin: auto;
}

.pull-up-chat {
  padding: 0;
  border: rgb(77, 21, 198) solid;
  /* border-radius: unset; */
}

.pull-up-chat ul {
  padding-left: 0.5em !important;
  list-style: none;
  margin: unset;
  max-height: calc(100% - 5em);
  overflow: auto;
  overflow-x: hidden;
  border-radius: unset;
}

.pull-up-chat h1 {
  color: rgb(227, 224, 236);
}

.pull-up-chat ul li {
  padding: 0.5em;
  border-radius: 0.5em;
  margin: 0.5em;
  background-color: #00090b;
  font-size: 0.8em;
  font-weight: bolder;
  transition: background 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  padding-bottom: 0.5em;
  padding-top: 0.2em;
}

.pull-up-chat ul li span {
  font-size: 0.8em;
  font-weight: bolder;
}

/* set inner color white */
.pull-up-chat ul li * {
  color: rgb(227, 224, 236);

}

.chat-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.chat-message-bottom {
  display: flex;
  flex-direction: row;
}

.chat-message-author-avatar {
  position: relative;
  border-radius: 25%;
  min-width: 3em;
  /* overflow:hidden; */
  height: 3em;
  border: 1px dashed rgb(227, 224, 236);
}

.chat-message-author-avatar * {
  border-radius: 25%;
}

.pull-up-chat ul li .chat-message-author {
  text-align: left;
  margin: 0.5em 0.5em 1em 0.5em;
  position: relative;

}

.chat-message-author-level {
  font-size: 0.66em;
  /* disable word wrap */
  white-space: nowrap;
  position: absolute;
  bottom: -1.5em;
  right: -0.75em;
  text-align: right;
  padding: 0.5em;
  border-radius: 25%;
  background: #4d15c6;
  font-weight: bolder;

  border: 1px dashed rgb(227, 224, 236);
  /* font-style: italic; */
  font-style: unset;
  font-weight: lighter;
}

.chat-message-author-address {
  text-align: left;
  /* font-size: 0.85em; */
  margin: 0.66em auto 0.66em 0.66em;
}

.pull-up-chat ul li .chat-message-content {
  text-align: left;
  font-size: 0.85em;
  margin: 0.5em 0.5em 1em 0.5em;
}



.pull-up-chat .pull-up-content {
  border-radius: unset;
  overflow: hidden;
}

.pull-up-chat .pull-up-content .chat-input {
  width: 100%;
  height: 5em;
}

.centered {
  margin: auto;
}

.chat-input {}

.pull-up-chat .chat-messages {
  max-height: calc(100% - 5em);
  overflow: auto;
  overflow-x: hidden;
  border-radius: unset;
}

/* make scrollbar of .chat-messages */

.pull-up-chat .chat-messages::-webkit-scrollbar {
  width: 0.5em;
  opacity: 0;
  background: rgb(77, 21, 198);

  /* width transition */
}

.pull-up-chat .chat-messages:hover::-webkit-scrollbar {
  width: 0.5em;
  opacity: 1;
}

.pull-up-chat .chat-messages::-webkit-scrollbar-track {
  background: rgb(77, 21, 198);
}

.pull-up-chat .chat-messages::-webkit-scrollbar-thumb {
  background: rgb(77, 21, 198);
  transition: all 0.2s ease-in-out;
}

.pull-up-chat .chat-messages:hover::-webkit-scrollbar-thumb {
  background: rgb(227, 224, 236);
}

.pull-up-chat .pull-up-content .chat-input {
  padding: 0.5em 0.75em;
}

.pull-up-chat .pull-up-content textarea {
  width: 100%;
  height: 5.5em;
  /* disable resize */
  resize: none;
  outline: none;
  border: unset;
  background: rgb(77, 21, 198);
  /* border-top: rgb(23, 9, 55) dashed 0.2em; */
  outline: none !important;
  border-radius: 0.5em;
  /* border on outside of box */
  color: rgb(227, 224, 236);
  font-size: 0.8em;
  padding: 0.66em 1.2em;
  max-height: 100%;
  overflow: auto;
  max-height: 100%;
  overflow: auto;
  background-color: #00090b;
}

/* placholder color text */
.pull-up-chat .pull-up-content textarea::-webkit-input-placeholder {
  color: rgba(227, 224, 236, 0.2);
}

.pull-up-chat .pull-up h1 {
  margin-top: 2.5em;
}

.pull-up-content,
.pull-upcontent * {
  color: white;
}

.invert {
  color: #00090b !important;
}

button.invert {
  color: inherit !important;
}

.pull-up.exited {
  transform: translate(0, calc(100%));
}

.pull-up.exiting {
  transform: translate(0, calc(100%));
  transition: all 0.35s ease-in-out, background 0s linear 0.35s !important;
}

.pull-up h1 {}

.pull-up-exit {
  flex: 0;
  height: 0;
  max-height: 0;
  margin-left: 0;
  z-index: 3;
  width: 100%;
}

.pull-up-exit>button {
  width: 2.5em;
  border-radius: 50%;
  background-color: inherit;
  height: 2.5em;
  position: relative;
  /* top: 9.5em; */
  /* left: 12.5em; */
  right: 0;
  top: 1em;
  border: 3px solid white;
  box-sizing: content-box;
  z-index: 3;
  display: none;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  justify-content: center;
  align-items: center;
  margin: unset;
  padding: unset;
  font-size: 1em;
  background: white;
  margin-left: calc(100% - 2.5em);
}

.pull-up-exit>button * {
  font-size: 1.2em;
  padding-top: 0.2em;
  padding-left: 0.1em;
}

.pull-up-exit>button {
  transition: all 0.2s ease-in-out, border 0s linear;
}

.pull-up-exit>button:hover {
  opacity: 1;
  cursor: pointer;
  transform: scale(1.1);
}

.pull-up-exit>button:active {
  opacity: 1;
  transform: scale(1.05);
}


.pull-up-exit.entering>button {
  display: flex;
  opacity: 0;
}

.pull-up-exit.entered>button {
  opacity: 1;
  display: flex;
}

.pull-up-exit.exiting>button {
  opacity: 0;
  display: flex;
}

.open-chat {
  position: absolute;
  bottom: 0.5em;
  right: 0.75em;
  font-size: 2em;
  padding: 0.5em;
  border-radius: 10%;
  background-color: rgb(77, 21, 198);
  border: none;
  transition: all 0.2s ease-in-out;
}

.open-chat.exiting,
.open-chat.exited {
  opacity: 0;
}

.open-chat.exited {
  display: none;
}

.open-chat * {
  color: rgb(227, 224, 236);
}

.open-chat:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.open-chat:active {
  transform: scale(1.05);
}

.ref-input:hover {
  cursor: pointer;
}

/* at and below 420 width update .cards */
@media screen and (max-width: 420px) {
  .card {
    margin: 0.5em auto;
    max-width: calc(60vw - 2em);
    width: 100%;
    min-width: 12ch;
  }

  .cards {
    margin: unset;
    margin-left: unset;
  }

  .cards>div:nth-of-type(1) {
    margin: 0.5em auto;
  }
}

button:hover,
input:hover {
  cursor: pointer;
}

button:disabled:hover,
input:disabled:hover {
  cursor: unset;
}

input[type="range"] {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  height: 2em;
  width: 20ch;
  max-width:90%;
  cursor: pointer;
  border-radius: 0;
  /* iOS */
  background: #d6e7f5;
  outline: 2px dashed #00090b33;
}
input[type="number"] {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  height: 2em;
  width: 20ch;
  max-width:90%;
  border-radius: 0;
  padding: 0 0.25em 0 0.25em;
  border: none;
  background: #d6e7f5;
  outline: 2px dashed #00090b33;
  color:#00090b !important;
  text-align: right;
}

::-webkit-slider-runnable-track {
  background: #CEE9EF;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 * 2. Shadow is negative the full width of the input and has a spread 
 *    of the width of the input.
 */
::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  /* 1 */
  height: 2em;
  background: #fff;
  box-shadow: -200px 0 0 200px #14c6ee;
  /* 2 */
  border: 2px solid #00090b33;
  /* 1 */
}

::-moz-range-track {
  height: 2em;
  background: #ddd;
}

::-moz-range-thumb {
  background: #fff;
  height: 2em;
  width: 20px;
  border: 3px solid #00090b33;
  border-radius: 0 !important;
  box-shadow: -200px 0 0 200px #14c6ee;
  box-sizing: border-box;
}

::-ms-fill-lower {
  background: #14c6ee;
}

::-ms-thumb {
  background: #fff;
  border: 2px solid #00090b33;
  height: 2em;
  width: 20px;
  box-sizing: border-box;
}

::-ms-ticks-after {
  display: none;
}

::-ms-ticks-before {
  display: none;
}

::-ms-track {
  background: #ddd;
  color: transparent;
  height: 2em;
  border: none;
}

::-ms-tooltip {
  display: none;
}

@keyframes feedFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    opacity: 1;
    transfrom: scale(1);
  }

}