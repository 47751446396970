.bonus-tasks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    min-height: 14em;
}

.description {
    line-height: 1.5em;
    max-width: 80%;
    text-align: center;
}

.bonus-tasks > button {
    padding: 1.5em 3em;
    background: #00090b;
    border: none;
    color: rgb(255, 215, 34);
    border-radius: 0.5em;
}
.bonus-tasks > h2 {
    margin: 0;
    padding: 0;
}