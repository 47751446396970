* {
  z-index: 2;
  font-family: "Press Start 2P";
}

#id {
  font-size: 18px;
}

body {
  max-width: 100vw;
  max-height: 100vh;
}

html,
body,
.App {
  overflow: hidden;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.App>div {
  color: white;
}

.App h1 {
  color: white;
}

.App h1.spin {
  animation: spin 1s infinite ease-out;
}

/* spin animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.background-design {
  position: absolute;
  max-width: 100vw;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.background-design::before {
  content: "";
  position: absolute;
  /* top: 50vh; */
  top: 0ch;
  left: min(-100vw, -100vh);
  width: max(200vw, 200vh);
  height: min(50vh, 50vw);
  transform: rotate(-45deg);
  /* z-index: -1; */
  background: linear-gradient(to left, rgba(35, 206, 163, 0.8), rgb(0, 0, 0));
  animation: sway 4s infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0.66s;

}

.background-design:nth-child(2)::before {
  background: linear-gradient(to left, rgb(21, 197, 168), rgb(0, 0, 0));
  height: min(35vh, 35vw);
  animation-delay: 0.33s;

}

.background-design:nth-child(2)::after {
  background: linear-gradient(to left, rgb(18, 163, 134), #011e25);
  height: min(25vh, 25vw);
  animation-delay: 0.66s;
}

.background-design::after {
  content: "";
  position: absolute;
  /* top: 50vh; */
  top: 0ch;
  left: min(-100vw, -100vh);
  width: max(200vw, 200vh);
  height: min(40vh, 40vw);
  transform: rotate(-45deg);
  /* z-index: -1; */
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgb(209, 209, 209));
  animation: sway 4s infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0.33s;
}

/* animation named sway which transtions the element down/right then up/left */
@keyframes sway {
  0% {
    transform: translate(0, 0) rotate(-45deg);
  }

  50% {
    transform: translate(-1ch, -1ch) rotate(-45deg);
  }

  100% {
    transform: translate(0, 0) rotate(-45deg);
  }
}

.sign-in {
  font-size: 8ch;
  position: absolute;
  margin: unset;
  padding: unset;
  top: calc(50vh - 0.5ch);
  left: calc(50vw - 0.5ch);
  text-shadow: rgb(65, 216, 171) 0.0ch 0.0ch 0.02ch;
  /* transforms should take place over 0.1s */
  transition: all 0.2s ease-in-out, transform 0.1s ease-in-out;
  /* disable selecting */
  -webkit-touch-callout: none;
  user-select: none;
}

.sign-in:hover {
  text-shadow: rgb(65, 216, 171) 0.03em 0.03em 0em;
  cursor: pointer;
  /* transform: rotate(5deg); */
}

.sign-in:active {
  text-shadow: rgb(25, 85, 67) 0.02em 0.02em 0.02em;
  /* rotate 20 degs */
  transform: rotate(-22deg);
}

.background-design.entering,
.background-design.exiting {
  z-index: 4;
}

.background-design.entering::before {
  /* have animation carry through */
  animation: wipe 2s ease-in-out forwards;
}

.background-design.entering::after {
  animation: wipe 1.5s ease-in-out forwards;

}

.background-design.exited::before,
.background-design.exited::after.background-design.exiting::before,
.background-design.exiting::after {
  transition: opacity 0.5s ease-in-out;
}

.background-design.exited::before,
.background-design.exited::after.background-design.exiting::before,
.background-design.exiting::after {
  transition: unset;
}

.background-design.entered::before,
.background-design.entered::after {
  opacity: 0;
  display: none;
}

.background-design.exited::before,
.background-design.exited::after {
  opacity: 1;
}

.background-design.exiting::before {
  /* have animation carry through */
  opacity: 1;
  animation: reversewipe 1.5s ease-out forwards;
  z-index: 4;
}

.background-design.exiting::after {
  opacity: 1;
  animation: reversewipe 1s ease-out forwards;
  z-index: 4;
}

.sign-in.exited {
  opacity: 0;
  animation: fadeIn 1s linear forwards;
  animation-delay: 0.66s;
}

.sign-in.spin {
  opacity: 1;
}

.sign-in.entering {
  animation: fadeOut 1s linear forwards;
  animation-delay: 0.66s;
}

.sign-in.exiting {
  opacity: 0 !important;
}




/* wipe diagnol animation */
@keyframes wipe {
  0% {
    transform: translate(0, 0) rotate(-45deg) scale(1);
  }

  100% {
    transform: translate(200vw, 200vw) rotate(-45deg);
  }
}

@keyframes reversewipe {
  0% {
    transform: translate(200vw, 200vw) rotate(-45deg);
  }

  100% {
    transform: translate(0, 0) rotate(-45deg) scale(1);
  }
}


* {
  font-family: "Press Start 2P";
  box-sizing: border-box;
  color: #00090b;
}

.card div,
.card span {
  color: #fff;
  line-height: 1.25ch;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* when focused show outline */
*:focus {
  outline: 2px dashed #00090b !important;
  transition: outline 0.2s ease-in-out;
}


body {
  background: #00090b;
}

.background {
  background: #00090b;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  opacity: 0;
}

.background.entering,
.background.exiting {
  z-index: 3;
  /* fade in */
  animation: fadeIn 1s ease-in-out forwards;
}

.background.entered,
.background.exited {
  animation: fadeOut 1s ease-in-out forwards, zIndex 1s ease-in-out forwards;
}

@keyframes zIndex {

  0%,
  99% {
    z-index: 2;
  }

  100% {
    z-index: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;

  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}